const numberDate = true;

export default [
  {
    title: 'ТИП ПРОТОКОЛА',
    params: [
      {
        title: 'ТИП ПРОТОКОЛА',
        key: 'protocol_type'
      },
      {
        title: 'ПРОТОКОЛ,НА ОСНОВАНИИ КОТОРОГО ПРОИСХОДИТ СОЗДАНИЕ',
        key: 'parent_offence_id'
      }
    ]
  },
  {
    title: 'УСТАНОВЛЕНИЕ СОБСТВЕННИКА',
    params: [
      {
        numberDate,
        title: 'ЗАПРОС НА УСТАНОВЛЕНИЕ СОБСТВЕННИКА  ',
        key:
          'request_establishment_owner_number request_establishment_owner_date',
        params: [
          {
            title: 'ДОКУМЕНТЫ',
            files: '',
            key: 'file_request_establishment_owner'
          },
          {
            title: 'РЕЗУЛЬТАТ',
            key: 'request_establishment_owner_result'
          }
        ]
      }
    ]
  },
  {
    title: 'СОСТАВЛЕНИЕ УВЕДОМЛЕНИЯ',
    params: [
      {
        numberDate,
        title: 'УВЕДОМЛЕНИЕ О ДОПУЩЕННОМ ПРАВОНАРУШЕНИИ ',
        key:
          'notice_offence_object_owner_number notice_offence_object_owner_date',
        params: [
          {
            title: 'СТАТЬЯ ПРАВОНАРУШЕНИЙ',
            key: 'article_offence'
          },
          {
            title: 'CТАТЬЯ ПРАВОНАРУШЕНИЙ',
            key: 'article_offence_basis'
          },
          {
            title: 'ДАТА НАПРАВЛЕНИЯ УВЕДОМЛЕНИЯ О ДОПУЩЕННОМ ПРАВОНАРУШЕНИИ',
            key: 'date_notification_offence'
          }
        ]
      }
    ]
  },
  {
    title: 'СОСТАВЛЕНИЕ ПРОТОКОЛА',
    params: [
      {
        numberDate,
        title: 'ПРОТОКОЛ ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ  ',
        key:
          'offence_protocol_object_owner_number offence_protocol_object_owner_date',
        params: [
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_offence_protocol_docs',
            func: protocol => {
              return {
                value: (protocol.files || []).filter(
                  e => e.folder_code === 'offence_protocol_docs'
                ),
                template: 'card-files'
              };
            }
          }
        ]
      },
      {
        numberDate,
        title: 'АКТ К ПРОТОКОЛУ ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ  ',
        key:
          'administrative_offense_protocol_act_number administrative_offense_protocol_act_date'
      },
      {
        title:
          'СВЕДЕНИЯ О ЛИЦЕ, В ОТНОШЕНИИ КОТОРОГО ВОЗБУЖДЕНО ДЕЛО ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ',
        key: 'place_owner_person_info'
      },
      {
        title:
          'СТАТУС ЛИЦА, В ОТНОШЕНИИ КОТОРОГО ВОЗБУЖДЕНО ДЕЛО ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ',
        key: 'place_owner_person_status'
      }
    ]
  },
  {
    title: 'ПРИВЛЕЧЕНИЕ К АДМИНИСТРАТИВНОЙ ОТВЕТСТВЕННОСТИ',
    params: [
      {
        title: 'РЕШЕНИЕ МИРОВОГО СУДА',
        key: 'world_judgment_decision'
      },
      {
        title: 'Дата окончания срока добровольной оплаты',
        key: 'voluntary_payment_end_date_object_owner',
        if: true,
        func: protocol => {
          return {
            value: protocol.world_judgment_decision === 1,
            template: 'base'
          };
        }
      },
      {
        title: 'Сумма назначенного штрафа, руб.',
        key: 'amount_of_penalty_object_owner',
        if: true,
        func: protocol => {
          return {
            value: protocol.world_judgment_decision === 3001,
            template: 'base'
          };
        }
      },
      {
        title: 'Причина отказа',
        key: 'world_judgment_reject_reason',
        if: true,
        func: protocol => {
          return {
            value: protocol.world_judgment_decision === 3003,
            template: 'base'
          };
        }
      },
      {
        title: 'ДАТА РЕШЕНИЯ МИРОВОГО СУДА',
        key: 'world_judgment_decision_date'
      },

      {
        title: 'НАЛИЧИЕ ХОДАТАЙСТВА:',
        key: 'has_petition',
        params: [
          {
            numberDate,
            title: 'ХОДАТАЙСТВО  ',
            key: 'petition_number petition_date'
          },
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_petition',
            func: protocol => {
              return {
                value: (protocol.files || []).filter(
                  e => e.folder_code === 'petition'
                ),
                template: 'card-files'
              };
            }
          }
        ]
      },
      {
        numberDate,
        title: 'ПОСТАНОВЛЕНИЕ О НАЗНАЧЕНИИ АДМИНИСТРАТИВНОГО НАКАЗАНИЯ',
        key:
          'decree_punish_object_owner_number decree_punish_object_owner_date',

        params: [
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_sentencing_order_docs',
            func: protocol => {
              return {
                value: (protocol.files || []).filter(
                  e => e.folder_code === 'sentencing_order_docs'
                ),
                template: 'card-files'
              };
            }
          }
        ]
      },
      {
        title: 'ДАТА НАСТУПЛЕНИЯ АДМИНИСТРАТИВНОЙ ОТВЕТСТВЕННОСТИ',
        key: 'administrative_responsibility_object_owner_date_begin'
      },
      {
        title: 'ДАТА ОКОНЧАНИЯ ПЕРИОДА АДМИНИСТРАТИВНОЙ ОТВЕТСТВЕННОСТИ',
        key: 'administrative_responsibility_object_owner_date_end'
      },
      {
        title: 'ДАТА ВРУЧЕНИЯ ПОСТАНОВЛЕНИЯ О ШТРАФЕ ИЛИ ДАТА ВОЗВРАТА С ПОЧТЫ',
        key: 'ak_decree_object_owner'
      }
    ]
  },
  {
    title: 'ПОДАЧА ЖАЛОБЫ',
    params: [
      {
        title: 'НАЛИЧИЕ ЖАЛОБЫ НА ПОСТАНОВЛЕНИЕ СУДА',
        key: 'decree_court_exist'
      },
      {
        title: 'НОМЕР ДЕЛА ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ СУДА',
        key: 'decree_court_case_number',
        if: true,
        func: protocol => {
          return {
            value: protocol.decree_court_exist,
            template: 'base'
          };
        }
      },
      {
        title: 'ДАТА РЕГИСТРАЦИИ ДЕЛА ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ СУДА',
        key: 'decree_court_registration_date',
        if: true,
        func: protocol => {
          return {
            value: protocol.decree_court_exist,
            template: 'base'
          };
        }
      },
      {
        title: 'РЕШЕНИЕ ПО ДЕЛУ ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ СУДА',
        key: 'decree_court_decision',
        if: true,
        func: protocol => {
          return {
            value: protocol.decree_court_exist,
            template: 'base'
          };
        }
      },
      {
        title: 'ДАТА РЕШЕНИЯ СУДА ПО ДЕЛУ ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ СУДА',
        key: 'decree_court_decision_date',
        if: true,
        func: protocol => {
          return {
            value: protocol.decree_court_exist,
            template: 'base'
          };
        }
      }
    ]
  },
  {
    title: 'ПОДАЧА АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ',
    params: [
      {
        title: 'НАЛИЧИЕ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ НА ПОСТАНОВЛЕНИЕ СУДА',
        key: 'appeal_complaint_exist'
      },
      {
        title: 'НОМЕР ДЕЛА ПО РАССМОТРЕНИЮ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ',
        key: 'appeal_complaint_case_number',
        if: true,
        func: protocol => {
          return {
            value: protocol.appeal_complaint_exist,
            template: 'base'
          };
        }
      },
      {
        title: 'ДАТА РЕГИСТРАЦИИ АПЕЛЛЯЦИИОННОЙ ЖАЛОБЫ',
        key: 'appeal_complaint_registration_date',
        if: true,
        func: protocol => {
          return {
            value: protocol.appeal_complaint_exist,
            template: 'base'
          };
        }
      },
      {
        title: 'РЕШЕНИЕ СУДА ПО РАССМОТРЕНИЮ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ',
        key: 'appeal_complaint_decision',
        if: true,
        func: protocol => {
          return {
            value: protocol.appeal_complaint_exist,
            template: 'base'
          };
        }
      },
      {
        title: 'РЕШЕНИЕ СУДА ПО РАССМОТРЕНИЮ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ, ОПИСАНИЕ',
        key: 'appeal_complaint_decision_description',
        if: true,
        func: protocol => {
          return {
            value: protocol.appeal_complaint_exist,
            template: 'base'
          };
        }
      },
      {
        title: 'ДАТА РЕШЕНИЯ СУДА ПО РАССМОТРЕНИЮ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ',
        key: 'appeal_complaint_decision_date',
        if: true,
        func: protocol => {
          return {
            value: protocol.appeal_complaint_exist,
            template: 'base'
          };
        }
      }
    ]
  },
  {
    title: 'ОПЛАТА ШТРАФА',
    params: [
      {
        title: 'ДАТА ОКОНЧАНИЯ СРОКА ДОБРОВОЛЬНОЙ ОПЛАТЫ',
        key: 'voluntary_payment_end_date_object_owner'
      },
      {
        title: 'СУММА НАЗНАЧЕННОГО ШТРАФА, РУБ.',
        key: 'amount_of_penalty_object_owner'
      },

      {
        title: 'СУММА ОПЛАЧЕННОГО ШТРАФА, РУБ.',
        key: 'amount_of_penalty_object_owner_paid'
      },
      {
        title: 'Дата взыскания',
        key: 'penalty_collection_date'
      },
      {
        title: 'Статус оплаты штрафа',
        key: 'penalty_payment_status_object_owner'
      }
    ]
  },
  {
    title: 'ПЕРЕДАЧА В ССП',
    params: [
      {
        numberDate,
        title:
          'ПОСТАНОВЛЕНИЕ О ВОЗБУЖДЕНИИ ДЕЛА ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ  ',
        key:
          'decision_initiate_proceedings_number decision_initiate_proceedings_date',
        params: [
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_decision_initiate_proceedings_docs',
            func: protocol => {
              return {
                value: (protocol.files || []).filter(
                  e => e.folder_code === 'decision_initiate_proceedings_docs'
                ),
                template: 'card-files'
              };
            }
          }
        ]
      },
      {
        title: 'ДАТА ПЕРЕДАЧИ ПОСТАНОВЛЕНИЯ В ССП',
        key: 'ssp_enforcement_date_place_owner'
      },
      {
        title: 'СТАТУС ПЕРЕДАЧИ ПОСТАНОВЛЕНИЯ',
        key: 'ssp_enforcement_status_place_owner'
      },
      {
        title: 'НАЛИЧИЕ ПРОТОКОЛА ЗА НЕУПЛАТУ ШТРАФА',
        key: 'ssp_enforcement_protocol_place_owner'
      },
      {
        title: 'СТАТУС ПЕРИОДА ДОБРОВОЛЬНОЙ ОПЛАТЫ',
        key: 'voluntary_payment_status_place_owner'
      }
    ]
  }
];
